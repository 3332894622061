.social-icon:hover{
    transform: rotateY(360deg);
    transition: 3s;
}

.profile-img{
    padding: 0.3rem;
}

.profile-img:hover {
    box-shadow: 0 0 2px 1px rgba(244, 246, 244, 0.5);
}

.bio{
    font-size: 2rem;
    font-weight: bold;
}

.greeting{
    font-size: 1.5rem;
    font-weight: bold;
}

.inline-link:hover{
    font-size: large;
}

/* App.css */
.avatar-custom {
    padding: 0.3rem;
  }

.avatar-custom:hover {
    box-shadow: 0 0 2px 1px rgba(244, 246, 244, 0.5);
}
  
  
